import React from "react"

/** components */
import { ColorPalette } from "./../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const RecentHeadlinesSection: React.FC<Props> = (props) => {
  return (
    <section className="container-fluid sectionBaseColor pt-5 pb-5">
      <div className="container pl-3 pr-3 text-start">
        <div className="container pb-5"></div>
        <div className="text-center pb3">
          <NorhartIcon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center" }}
        >
          Norhart News
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle">Recent Headlines</h3>
        <h2 className="contentHeroSubTitle" style={{ color: "rgba(83,118,136,0.8)", opacity: "0.9" }}>
          We invite you to stay up-to-date and informed with our announcements, articles, and industry shout-outs!
        </h2>

        <div className="row mt-5 mb-5">
          <div className="col-sm-6">
            <div
              className="card card-site high"
              style={{
                borderRadius: "8px",
                border: "transparent",
                padding: "0px",
                backgroundColor: "#FFFFFF",
                color: "#444444",
              }}
            >
              <div className="item">
                <img
                  src="/news/norhart-lexington-lofts-blaine-apartment-news-2019.webp"
                  loading="lazy"
                  decoding="async"
                  width="500"
                  height="250"
                  alt="Norhart Lexington Loft Apartments in Blaine News"
                />
              </div>
              <div className="p-3">
                <h3 className="cardHeader-site pb-2 ms-2 me-2" style={{ fontSize: "20px" }}>
                  <strong>Lexington Loft Luxury Apartments</strong>
                </h3>
                <h4 className="pb-4 ms-2 me-2" style={{ fontSize: "12px" }}>
                  Quad Community Press - 2019
                </h4>
                <h4
                  className="cardText-site pt-0 pb-0 ms-2 me-2"
                  style={{
                    color: "#555555",
                    opacity: "0.8",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  The city of Lexington will be home to the largest apartment building that our company has built, yet.
                  Comprised of 355 units, Lexington Lofts will take 3.5 years to complete. It will service not only
                  luxury apartments, but luxury amenities and top notch technology and innovation.
                </h4>

                <div
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <hr
                    className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
                    style={{
                      borderColor: "rgba(0, 0, 0, 0.2) !important",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.presspubs.com/quad/lexington-lofts-not-a-lexington-building/article_8b170eba-ef91-11e9-b460-dfaa05c836e6.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-6">
            <div
              className="card card-site high"
              style={{
                borderRadius: "8px",
                border: "transparent",
                padding: "0px",
                backgroundColor: "#FFFFFF",
                color: "#444444",
              }}
            >
              <div className="item">
                <img
                  src="/news/norhart-encore-forest-lake-apartments-news-2019.webp"
                  loading="lazy"
                  decoding="async"
                  width="500"
                  height="250"
                  alt="Norhart Lexington Loft Apartments in Blaine News"
                />
              </div>
              <div className="p-3">
                <h3 className="cardHeader-site pb-2 ms-2 me-2" style={{ fontSize: "20px" }}>
                  <strong>Encore Apartments - "Digging In"</strong>
                </h3>
                <h4 className="pb-4 ms-2 me-2" style={{ fontSize: "12px" }}>
                  Forest Lake Times - 2019
                </h4>
                <h4
                  className="cardText-site pt-0 pb-0 ms-2 me-2"
                  style={{
                    color: "#555555",
                    opacity: "0.8",
                    fontSize: "18px",
                    lineHeight: "28px",
                  }}
                >
                  Construction crews have been working on clearing the land next to the Mill Pond Forest Apartments
                  (northeast corner of the intersection of 11th Avenue Southwest and Fourth Street Southwest). Local
                  housing company Norhart is planning a new 91-unit building called Encore.
                </h4>

                <div
                  style={{
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <hr
                    className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
                    style={{
                      borderColor: "rgba(0, 0, 0, 0.2) !important",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  />
                </div>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.hometownsource.com/forest_lake_times/news/business/digging-in/article_548f3b42-8718-11e9-bb0d-431d66703ab4.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default RecentHeadlinesSection
