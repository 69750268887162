import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import { theme } from "../components/Theme"
import Layout from "../components/Layout"

/** static sections */
import RecentHeadlinesSection from "../components/static-sections/RecentHeadlinesSection"
import NewsArticlesSection from "../components/static-sections/NewsArticlesSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import NorhartDarkCircleIcon from "../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

interface Props {
  data: any
}

/** const */
const NewsPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/news/",
          name: "News | Norhart",
          image: `${config.siteMetadata.siteUrl}/news/news-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="News | Norhart"
      description="Stay up-to-date and informed with Norhart upcoming apartment announcements, articles, and industry shout-outs in the Minneapolis/ St. Paul metro area."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/news/norhart-news-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/news/norhart-news-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Headlines"
        subtitle="Engaging Norhart Stories"
        imageTitle="Norhart News"
        image="/news/norhart-news-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection title="Norhart News" tagLine="Stay up-to-date and informed" colorPalette={colorPalette} />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <RecentHeadlinesSection colorPalette={colorPalette} />

      <NewsArticlesSection colorPalette={colorPalette} />

      <AwardsSection
        header="Norhart Apartments"
        title="Award Winning Apartments"
        subtitle="We invite you to explore our Forest Lake and Blaine Minnesota smart apartments. Once you experience Norhart's smart living, believe me there is no going back!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default NewsPage
