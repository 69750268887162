import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"
/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const NewsArticlesSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-5"
      style={{
        background: `linear-gradient(${props.colorPalette.heroTop} 30%, ${props.colorPalette.heroBottom} 70%)`,
        color: "#FFF",
      }}
    >
      <div className="container pb-5 pt-5">
        <div className="text-center pb-2">
          <NorhartIcon />
        </div>
        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center"
          style={{ fontSize: "15px" }}
        >
          Norhart News
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-3 mb-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <div className="contentHeroSpace hidden-xs"></div>
        <h1 className="contentHeroTitle" style={{ color: "#FFF" }}>
          Norhart Past Articles
        </h1>
        <h2 className="contentHeroSubTitle" style={{ color: "#FFF", opacity: "0.8" }}>
          We have been around a long time, did you know that? Make sure to catch up on Norhart's past accomplishments,
          news and more.
        </h2>

        <div id="series1" className="row mt-5">
          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#FFFFFF",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-gateway-green-forest-lake-apartments-news-2019.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Gateway Green Apartments in Forest Lake News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">First Smart Apartments Coming to Forest Lake</h3>
                <h4 className=" newsContent">
                  Norhart was awarded a Progress Minnesota award for innovative smart apartment technology
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.globest.com/2019/05/01/first-smart-apartment-complex-opening-in-forest-lake-mn/?slreturn=20190907171215"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-growing-progress-in-minnesota-news.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Growing Progress in Minnesota News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Norhart's Growing Progress in Minnesota</h3>
                <h4 className=" newsContent">
                  Norhart's "smart” technology is beginning to have an impact in the apartment rental business
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://finance-commerce.com/2019/04/progress-mn-norhart/"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#FFFFFF",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-smart-apartments-introduced-in-blaine-new.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Smart Apartments Introduced in Blaine News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Smart Apartments Introduced in Blaine</h3>
                <h4 className=" newsContent">
                  Smart technology is easy to find – from smartphones to smart speakers to smart refrigerators
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.hometownsource.com/abc_newspapers/free/smart-apartments-introduced-to-blaine-market/article_781778b2-ece1-11e8-8e63-efa6d2a3d409.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="series2" className="row mt-5">
          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-tales-from-the-metro-north-chamber-news.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Tales from the Metro North Chamber News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Norhart Tales from the Metro North Chamber</h3>
                <h4 className=" newsContent">
                  I met Mike Kaeding at Emberwood Apartments thirty minutes before our scheduled interview
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://angco.biz/tales-from-the-metronorth-chamber-mike-kaeding"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-score-success-story-news.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart is a SCORE Success Story News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Norhart is a SCORE Success Story</h3>
                <h4 className=" newsContent">
                  25 years ago, Ed Kaeding started Norhart, a company that builds high-quality, affordable homes and
                  apartments
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.score.org/success-story/norhart"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-gateway-green-apartment-breaking-ground.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Gateway Green Apartment Breaking Ground News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Gateway Green Apartment Breaking Ground</h3>
                <h4 className=" newsContent">
                  Norhart, an apartments, self-storage, and commercial rental and construction services company, broke
                  ground
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.hometownsource.com/forest_lake_times/apartment-ground-breaking/image_3eed05a2-8535-11e8-a991-5faf55968470.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>

        <div id="series3" className="row mt-5">
          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/twin-cities-based-norhart-expands-portfolio-news.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Twin-Cities-based Norhart Expands Portfolio News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Twin Cities Based Norhart Expands Portfolio</h3>
                <h4 className=" newsContent">
                  Norhart’s enthusiasm for the impact IOTAS will have on the lives of their residents is contagious
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.iotashome.com/twin-cities-based-norhart-expands-portfolio-first-smart-apartment-community-blaine-minnesota/"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-receives-national-recognition.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt="Norhart Receives National Recognition News"
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Norhart Receives National Recognition</h3>
                <h4 className=" newsContent">
                  Norhart, a company that builds and manages residential properties in Forest Lake and Blaine{" "}
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.hometownsource.com/forest_lake_times/news/business/norhart-receives-national-recognition-as-an-american-small-business-champion/article_6358864c-4295-11e8-b5c8-47b58f43e53c.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>

          <div className="col-sm-4">
            <div
              className="card card-site high"
              style={{
                borderRadius: "5px",
                borderWidth: "0px",
                padding: "0px",
                backgroundColor: "#ffffff",
                color: "#444444",
              }}
            >
              <div
                className="item"
                style={{
                  height: "250px",
                }}
              >
                <img
                  src="/news/norhart-gateway-green-apartment-breaking-ground.webp"
                  width="616"
                  height="372"
                  loading="lazy"
                  decoding="async"
                  alt=""
                />
              </div>
              <div className="p-3">
                <h3 className=" newsTitle">Norhart Property Management Gives Back</h3>
                <h4 className=" newsContent">
                  Norhart property management give its tenants an opportunity to give back this holiday season by
                  donating
                </h4>
                <a
                  className="btn btn-md mt-1 ms-2 me-2"
                  href="https://www.hometownsource.com/forest_lake_times/helping-the-homeless/article_ec9ed622-ec00-11e7-9aa9-6bf22e87bf70.html"
                  target="_blank"
                  style={{
                    backgroundColor: `${props.colorPalette.heroTop}`,
                    color: "#fff",
                  }}
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default NewsArticlesSection
